import React, { useState, useEffect } from "react";
import Button from "../Button/Button";
import Counter from './../Counter/Counter';

import './CatalogCard.scss'
import clsx from "clsx";

import NoPhoto from '../../assets/images/no-photo.jpeg'

const inStockText = {
  ru: 'В наличии',
  uh: 'В наявності'
}

const empty = {
  ru: 'Ожидается',
  uh: 'Очікується'
}


const CatalogCard = ({ data, isTable }) => {
  const { imgUrl, title, priceList, isInStock, id } = data
  const language = 'ru'

  const [srcImg, setSrcImg] = useState('')
  const [quantity, setQuantity] = useState(0)
  const [priceItem, setPriceItem] = useState(null)
  const [basketList, setBasketList] = useState([])

  useEffect(() => {
    if (!imgUrl) return

    const getImg = async () => {
      let resource = ''

      resource = await (import(`../../assets/images/catalog/${imgUrl}`))

      setSrcImg(resource.default)
    }

    getImg()
    
  }, [imgUrl])

  useEffect(() => {
    const index = priceList.findIndex((el, i) => el.typeId == 1)

    if (index >= 0) {
      setPriceItem(priceList[index])
    }
  }, [priceList])


  useEffect(() => {
    const basket = JSON.parse(localStorage.getItem('basket'))
      
    if (basket && basket.length) {
      setBasketList(basket)

      let indexData = basket.findIndex((el, i) => el.id == id)
  
      if (indexData >= 0) {
        setQuantity(basket[indexData].quantity)
      }
    }

  }, [])

  const changeQuantity = (num) => {
    setQuantity(num)
    setBasket(num)
  }

  const setBasket = (num) => {
    let basketNew = [...basketList]
    const index = basketNew?.findIndex((el, i) => el.id == id)

    if (num == 0) {
      if (index >= 0) {
        basketNew.splice(index, 1)
      }
    } else {
      const newItem = {
        id,
        quantity: num,
        typeId: priceItem
      }
  
      if (index >= 0) {
        basketNew[index] = newItem

      } else {
        basketNew.push(newItem)
      }
      
    }

    setQuantity(num)
    setBasketList(basketNew)
    localStorage.setItem('basket', JSON.stringify(basketNew))
  }
  
  return (
    <div className={clsx("catalog-card", isTable ? '' : 'catalog-card--col', !isInStock ? 'catalog-card--disable' : '' )}>
      <div className="catalog-card__head">
        <div className="catalog-card__img">
          <img src={srcImg ? srcImg : NoPhoto} alt="photo" />
        </div>
      </div>

      <div className="catalog-card__content">
        <div className="catalog-card__info">
          <div className="catalog-card__instock mb-1">
            {isInStock ?
              (<div className="in-stock">
                <span className="in-stock__icon">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                    <path d="M 41.9375 8.625 C 41.273438 8.648438 40.664063 9 40.3125 9.5625 L 21.5 38.34375 L 9.3125 27.8125 C 8.789063 27.269531 8.003906 27.066406 7.28125 27.292969 C 6.5625 27.515625 6.027344 28.125 5.902344 28.867188 C 5.777344 29.613281 6.078125 30.363281 6.6875 30.8125 L 20.625 42.875 C 21.0625 43.246094 21.640625 43.410156 22.207031 43.328125 C 22.777344 43.242188 23.28125 42.917969 23.59375 42.4375 L 43.6875 11.75 C 44.117188 11.121094 44.152344 10.308594 43.78125 9.644531 C 43.410156 8.984375 42.695313 8.589844 41.9375 8.625 Z"></path>
                  </svg>
                </span>
                <span className="in-stock__text">{inStockText[language]}</span>
              </div>)
              :
              (<span className="in-stock__text in-stock__text--empty">{empty[language]}</span>)}
          </div>
          <span className="catalog-card__title">{title[language]}</span>
        </div>

        <div className="catalog-card__footer">
          <div className="catalog-card__price mr-2">
            {priceList[0].cost.old && isInStock ? (
              <span className="catalog-card__cost catalog-card__cost--old">{priceList[0].cost.old} грн</span>
            ) : ''}
            <span className="catalog-card__cost">{priceList[0].cost.new} грн</span>
          </div>

          
            {quantity ? (
              <div  className="catalog-card__buttons">
                <Counter num={quantity} changeNum={changeQuantity} />
              <div className="catalog-card__remove" onClick={() => setBasket(0)}>
                  <svg version="1.1" viewBox="0 0 64 64"  ><g><g id="Icon-Trash" transform="translate(232.000000, 228.000000)"><polygon class="st0" points="-207.5,-205.1 -204.5,-205.1 -204.5,-181.1 -207.5,-181.1    "/><polygon class="st0"points="-201.5,-205.1 -198.5,-205.1 -198.5,-181.1 -201.5,-181.1    "/><polygon class="st0" points="-195.5,-205.1 -192.5,-205.1 -192.5,-181.1 -195.5,-181.1    "/><polygon class="st0" points="-219.5,-214.1 -180.5,-214.1 -180.5,-211.1 -219.5,-211.1    "/><path class="st0" d="M-192.6-212.6h-2.8v-3c0-0.9-0.7-1.6-1.6-1.6h-6c-0.9,0-1.6,0.7-1.6,1.6v3h-2.8v-3     c0-2.4,2-4.4,4.4-4.4h6c2.4,0,4.4,2,4.4,4.4V-212.6"/><path class="st0" d="M-191-172.1h-18c-2.4,0-4.5-2-4.7-4.4l-2.8-36l3-0.2l2.8,36c0.1,0.9,0.9,1.6,1.7,1.6h18     c0.9,0,1.7-0.8,1.7-1.6l2.8-36l3,0.2l-2.8,36C-186.5-174-188.6-172.1-191-172.1"/></g></g></svg>
                </div>
              </div>
          ) : (
                <Button claz='catalog-card__btn' onClick={() => setBasket(1)}>
                  <svg fill="none" height="27" viewBox="0 0 30 27" width="30" xmlns="http://www.w3.org/2000/svg"><path d="M1.39999 1.70001H6.60001" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/><path d="M6.60001 1.70001L11 18.9" stroke-linecap="round" strokeMiterlimit="10" strokeWidth="2"/><path d="M11.8 18.9H28.3" stroke-linecap="round" strokeMiterlimit="10" strokeWidth="2"/><path d="M13.8 25.7C15.4569 25.7 16.8 24.3569 16.8 22.7C16.8 21.0432 15.4569 19.7 13.8 19.7C12.1431 19.7 10.8 21.0432 10.8 22.7C10.8 24.3569 12.1431 25.7 13.8 25.7Z" stroke-linecap="round" strokeMiterlimit="10" strokeWidth="2"/><path d="M25.3 25.7C26.9568 25.7 28.3 24.3569 28.3 22.7C28.3 21.0432 26.9568 19.7 25.3 19.7C23.6431 19.7 22.3 21.0432 22.3 22.7C22.3 24.3569 23.6431 25.7 25.3 25.7Z" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/><path d="M25.7 14.6H11.3C10.7 14.6 10.1 14.2 10 13.6L8.1 6.90001C7.9 6.00001 8.49999 5.20001 9.39999 5.20001H27.5C28.4 5.20001 29.1 6.10001 28.8 6.90001L26.9 13.6C26.9 14.2 26.4 14.6 25.7 14.6Z" stroke-linecap="round" strokeMiterlimit="10" strokeWidth="2"/></svg>
                </Button> 
                
            )}
            


        </div>
      </div>
    </div>
  )
}

export default CatalogCard