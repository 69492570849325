import React from 'react'
import { menuList } from '../../helpers/headerList'
import Button from '../../components/Button/Button'
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg'
import { useNavigate } from 'react-router-dom'
import './Header.scss'

const Header = () => {
	const navigate = useNavigate()

	return (
		<header className="header">
			<div className="container">
				<div className="header__wrap">
					<div className="header__logo"
						onClick={() => { navigate('/')}}>
						<LogoIcon/>
					</div>
					<nav className="nav">
						<ul className="nav__list">
							{menuList.map((el, i) => (
								<li className="nav__item" key={i}>
									<span className="nav__link js-burger-close"
										onClick={() => {navigate('/' + el.link)}}>
										{el.title}
									</span>
								</li>
							))}
						</ul>
					</nav>
					<Button claz='header__btn'/>
					<div className="burger">
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
			</div>
		</header>
	)
}

export default Header
