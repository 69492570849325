import React, { useState } from "react";

import './Counter.scss'
import clsx from "clsx";

const Counter = ({num = 1, changeNum = () => {}}) => {
  const [currentNum, setCurrentNum] = useState(num)

  const changeCurrentNum = (num) => {
    let newNum;

    if (num <= 0) {
      newNum = 0
    } else if (num >= 50) {
      newNum = 50
    } else {
      newNum = num
    }

    setCurrentNum(newNum);
    changeNum(newNum)
  }


  return (
    <div className="counter">
      <div className={clsx("counter__min", currentNum == 0 ? 'disable' : '')} onClick={() => changeCurrentNum(currentNum - 1)}></div>
      <div className="counter__num">{currentNum}</div>
      <div className={clsx("counter__plus", currentNum >= 50 ? 'disable' : '')} onClick={() => changeCurrentNum(currentNum + 1)}></div>
    </div>
  )
}

export default Counter