import React from "react";

import "./ProductsSection.scss";

import productImg1 from "../../assets/images/growing-img-1.jpg";

const ProductsSection = () => {
  return (
    <section className="section products-section">
      <div className="container">
        <h2 className="section-title text-center">Наши продукты</h2>
        <div className="our-products">
          <div className="our-products__row">
            <div className="our-products__col">
              <div className="our-product our-product--big">
                <div className="our-product__wrap">
                  <div className="our-product__img">
                    <img src={productImg1} alt="" />
                  </div>
                  <div className="our-product__footer">
                    <h3 className="our-product__name">Наборы для выращивания</h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.264 4.87002C14.367 6.61702 14.903 8.10002 15.93 9.48002C16.262 9.92602 17.064 10.737 17.453 11.021L17.726 11.22L9.62302 11.23L1.52002 11.24V12.76L9.62302 12.77L17.726 12.78L17.453 12.979C17.303 13.089 16.962 13.395 16.696 13.659C15.206 15.139 14.391 16.973 14.264 19.13L14.244 19.48H15.76V19.19C15.761 18.562 15.984 17.595 16.303 16.838C16.635 16.05 17.086 15.39 17.738 14.738C18.39 14.086 19.05 13.635 19.838 13.303C20.595 12.984 21.562 12.761 22.19 12.76H22.48V11.24H22.19C21.562 11.239 20.595 11.016 19.838 10.697C19.05 10.365 18.39 9.91402 17.738 9.26202C17.086 8.61002 16.635 7.95002 16.303 7.16202C15.984 6.40502 15.761 5.43802 15.76 4.81002V4.52002H14.244L14.264 4.87002Z"
                        fill="#80b500"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-products__col">
              <div className="our-product our-product--medium">
                <div className="our-product__wrap">
                  <div className="our-product__img">
                    <img src={productImg1} alt="" />
                  </div>
                  <div className="our-product__footer">
                    <h3 className="our-product__name">Семена для микрозелени</h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.264 4.87002C14.367 6.61702 14.903 8.10002 15.93 9.48002C16.262 9.92602 17.064 10.737 17.453 11.021L17.726 11.22L9.62302 11.23L1.52002 11.24V12.76L9.62302 12.77L17.726 12.78L17.453 12.979C17.303 13.089 16.962 13.395 16.696 13.659C15.206 15.139 14.391 16.973 14.264 19.13L14.244 19.48H15.76V19.19C15.761 18.562 15.984 17.595 16.303 16.838C16.635 16.05 17.086 15.39 17.738 14.738C18.39 14.086 19.05 13.635 19.838 13.303C20.595 12.984 21.562 12.761 22.19 12.76H22.48V11.24H22.19C21.562 11.239 20.595 11.016 19.838 10.697C19.05 10.365 18.39 9.91402 17.738 9.26202C17.086 8.61002 16.635 7.95002 16.303 7.16202C15.984 6.40502 15.761 5.43802 15.76 4.81002V4.52002H14.244L14.264 4.87002Z"
                        fill="#80b500"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="our-product our-product--small">
                <div className="our-product__wrap">
                  <div className="our-product__img">
                    <img src={productImg1} alt="" />
                  </div>
                  <div className="our-product__footer">
                    <h3 className="our-product__name">Микрогрин в лоточках</h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.264 4.87002C14.367 6.61702 14.903 8.10002 15.93 9.48002C16.262 9.92602 17.064 10.737 17.453 11.021L17.726 11.22L9.62302 11.23L1.52002 11.24V12.76L9.62302 12.77L17.726 12.78L17.453 12.979C17.303 13.089 16.962 13.395 16.696 13.659C15.206 15.139 14.391 16.973 14.264 19.13L14.244 19.48H15.76V19.19C15.761 18.562 15.984 17.595 16.303 16.838C16.635 16.05 17.086 15.39 17.738 14.738C18.39 14.086 19.05 13.635 19.838 13.303C20.595 12.984 21.562 12.761 22.19 12.76H22.48V11.24H22.19C21.562 11.239 20.595 11.016 19.838 10.697C19.05 10.365 18.39 9.91402 17.738 9.26202C17.086 8.61002 16.635 7.95002 16.303 7.16202C15.984 6.40502 15.761 5.43802 15.76 4.81002V4.52002H14.244L14.264 4.87002Z"
                        fill="#80b500"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="our-product our-product--small">
                <div className="our-product__wrap">
                  <div className="our-product__img">
                    <img src={productImg1} alt="" />
                  </div>
                  <div className="our-product__footer">
                    <h3 className="our-product__name">Срез микрогрина</h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.264 4.87002C14.367 6.61702 14.903 8.10002 15.93 9.48002C16.262 9.92602 17.064 10.737 17.453 11.021L17.726 11.22L9.62302 11.23L1.52002 11.24V12.76L9.62302 12.77L17.726 12.78L17.453 12.979C17.303 13.089 16.962 13.395 16.696 13.659C15.206 15.139 14.391 16.973 14.264 19.13L14.244 19.48H15.76V19.19C15.761 18.562 15.984 17.595 16.303 16.838C16.635 16.05 17.086 15.39 17.738 14.738C18.39 14.086 19.05 13.635 19.838 13.303C20.595 12.984 21.562 12.761 22.19 12.76H22.48V11.24H22.19C21.562 11.239 20.595 11.016 19.838 10.697C19.05 10.365 18.39 9.91402 17.738 9.26202C17.086 8.61002 16.635 7.95002 16.303 7.16202C15.984 6.40502 15.761 5.43802 15.76 4.81002V4.52002H14.244L14.264 4.87002Z"
                        fill="#80b500"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductsSection;
