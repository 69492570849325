const menuList = [
	{
		title: 'Home',
		link: '',
	},
	{
		title: 'Catalog',
		link: 'catalog',
	},
	{
		title: 'About',
		link: 'about',
	},
	{
		title: 'Contacts',
		link: 'contacts',
	},
	{
		title: 'Growing',
		link: 'growing',
	},
]

export { menuList }
