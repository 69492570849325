import React from 'react'
import clsx from 'clsx'
import './Button.scss'

const Button = (props) => {
  const claz = props.claz ? props.claz : ''
  const typeClazBtn = props.typeClazBtn ? props.typeClazBtn : 'primary'
  const typeBtn = props.typeBtn ? props.typeBtn : 'button'
  const onClick = props.onClick ? props.onClick : () => { }

  return (
    <button className={clsx(claz, typeClazBtn, 'btn')} onClick={onClick} type={typeBtn} >{props.children}</button>
  )
}

export default Button