const introSliderList = [
	{
		title: 'Начни свой путь к здоровому питанию сегодня!',
		subtitle: 'Микрогрин: маленький секрет больших изменений в твоем здоровье!',
	},
	{
		title: 'Выращивай Суперфуд самостоятельно!',
		subtitle:
			'Наши Наборы для Выращивания Микрогрина - Здоровье, которое ты сам создаешь!',
	},
	{
		title: 'Выбирай качественные семена',
		subtitle: 'Здоровые Семена: Залог успешного урожая',
	},
	{
		title: 'Забудь о сезонных ограничениях',
		subtitle:
			'На нашей ферме мы выращиваем с любовью микрогрин круглый год для вашего здоровья!',
	},
]

export default introSliderList
