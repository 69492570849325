import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import introSliderList from "../../helpers/introSlider";
import { Pagination } from "swiper/modules";

import bgImg1 from "../../assets/images/slider-bg1.jpg";
import bgImg2 from "../../assets/images/slider-bg1.jpg";
import bgImg3 from "../../assets/images/slider-bg1.jpg";
import bgImg4 from "../../assets/images/slider-bg1.jpg";

import "./IntroSection.scss";
import "swiper/css/bundle";

const sliderList = [bgImg1, bgImg2, bgImg3, bgImg4];

const IntroSection = () => {
  return (
    <section className="intro-section">
      <div className="container">
        <div className="intro-slider js-intro-slider">
          <Swiper
            modules={[Pagination]}
            spaceBetween={0}
            slidesPerView={1}
            speed={1200}
            centerInsufficientSlides={true}
            loop={true}
            effect={"fade"}
            fadeEffect={{ crossFade: true }}
            pagination={{ clickable: true, type: "bullets" }}
            onSlideChange={() => console.log("slide change")}
            scrollbar={{ draggable: true }}
          >
            {introSliderList.map((slider, i) => (
              <SwiperSlide key={i}>
                <div className="swiper-slide swiper-slide--one">
                  <div className="swiper-slide__wrap">
                    {/* <img src={sliderList[i]} alt={'img'}/> */}
                    <div className="swiper-slide__content">
                      <h3 className="swiper-slide__title">{slider.title}</h3>
                      <p className="swiper-slide__text">{slider.subtitle}</p>
                      <a href="#" className="btn primary swiper-slide__btn">
                        Подробнее
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default IntroSection;
