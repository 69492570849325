import React from "react";
import "./HomePage.scss";
import Header from "../../layout/Header/Header.jsx";

import IntroSection from "../../sections/IntroSection/IntroSection.jsx";
import AboutSection from "../../sections/AboutSection/AboutSection.jsx";
import ProductsSection from "../../sections/ProductsSection/ProductsSection.jsx";
import GrowingSection from "../../sections/GrowingSection/GrowingSection.jsx";
import StartSection from "../../sections/StartSection/StartSection.jsx";
import DifferenceSection from "../../sections/DifferenceSection/DifferenceSection.jsx";
import LeadersSection from "../../sections/LeadersSection/LeadersSection.jsx";
import LoveSection from "../../sections/LoveSection/LoveSection.jsx";
import PopularSection from "../../sections/PopularSection/PopularSection.jsx";
import RestaurantSection from "../../sections/RestaurantSection/RestaurantSection.jsx";

import Footer from "../../layout/Footer/Footer.jsx";

const HomePage = () => {
  return (
    <div id="luxy" className="wrapper">
      <Header />

      <IntroSection />
      <AboutSection />
      <ProductsSection />
      <GrowingSection />
      <StartSection />
      <DifferenceSection />
      <LeadersSection />
      <LoveSection />
      <PopularSection />
      <RestaurantSection />
	  
      <Footer />
    </div>
  );
};

export default HomePage;
