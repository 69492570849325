import React from "react";

import "./GrowingSection.scss";

import growingImg1 from "../../assets/images/growing-img-1.jpg";
import growingImg2 from "../../assets/images/growing-img-2.jpg";
import growingImg3 from "../../assets/images/growing-img-3.jpg";
import growingImg4 from "../../assets/images/growing-img-4.jpg";

const GrowingSection = () => {
  return (
    <section className="section growing-section">
      <div className="container">
        <h2 className="section-title text-center">Выращивай дома</h2>
        <p className="section-text text-center">
          Сей, Расти, Наслаждайся: Создай свой собственный уголок зелени!
        </p>
        <div className="growing">
          <div className="growing__row">
            <div className="growing__col">
              <div className="growing__descr">
                <h3 className="growing__title">Простота</h3>
                <p className="growing__text">
                  Выращивание микрозелени — это легко! Весь процесс у Вас займет
                  всего 5 минут в день. Наш набор включает подробныую
                  инструкцию, и вам потребуется только вода, подоконник и капля
                  времени что бы вырастить суперфуд.
                </p>
              </div>
            </div>
            <div className="growing__col">
              <div className="growing__img">
                <img src={growingImg1} alt="" />
              </div>
            </div>
          </div>
          <div className="growing__row">
            <div className="growing__col">
              <div className="growing__descr">
                <h3 className="growing__title">Полезность</h3>
                <p className="growing__text">
                  Микрогрин бьет рекорды по питательности! Содержит гораздо
                  больше полезных веществ, чем взрослые растения того же вида.
                  Поднимите планку своего здорового питания с нашими наборами
                  для выращивания.
                </p>
              </div>
            </div>
            <div className="growing__col">
              <div className="growing__img">
                <img src={growingImg2} alt="" />
              </div>
            </div>
          </div>
          <div className="growing__row">
            <div className="growing__col">
              <div className="growing__descr">
                <h3 className="growing__title">Экологичность без Химии</h3>
                <p className="growing__text">
                  Оптимизируйте свой выбор в пользу экологии с нашими наборами!
                  Наш набор включает в себя экологические льняные коврики и
                  семена без стимуляторов роста и других химических добавок.
                  Выращивайте 100% био суперфуд без земли, грязи и химии.
                </p>
              </div>
            </div>
            <div className="growing__col">
              <div className="growing__img">
                <img src={growingImg3} alt="" />
              </div>
            </div>
          </div>
          <div className="growing__row">
            <div className="growing__col">
              <div className="growing__descr">
                <h3 className="growing__title">Медитация в росте</h3>
                <p className="growing__text">
                  Выращивание микрозелени — это не только про растения. Это ваш
                  путь к медитации и расслаблению. Выращивайте свою зелень,
                  наслаждайтесь процессом и проводите качественное время с
                  семьей. Разгружайте ум, растягивайте время и наслаждайтесь
                  плодами вашего труда вместе!
                </p>
              </div>
            </div>
            <div className="growing__col">
              <div className="growing__img">
                <img src={growingImg4} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GrowingSection;
