import React from "react";

import "./LeadersSection.scss";

import growingImg1 from "../../assets/images/growing-img-1.jpg";

const LeadersSection = () => {
  return (
    <section className="section leaders-section">
      <div className="container">
        <h2 className="section-title text-center">Наши лидеры продаж семян</h2>
        <p className="section-text text-center">Семена подходят для выращивания микрозелени и рассады</p>
      </div>
    </section>
  );
};

export default LeadersSection;
