import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './routers/AppRouter'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

const jsx = (
	<BrowserRouter history={history}>
		<AppRouter />
	</BrowserRouter>
)

let hasRendered = false

const root = ReactDOM.createRoot(document.getElementById('root'))

const renderApp = () => {
	if (!hasRendered) {
		root.render(jsx)
		hasRendered = true
	}
}

renderApp()

// root.render(
// 	<React.StrictMode>
// 		<App />
// 	</React.StrictMode>
// )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
