import React, { useState, useEffect } from "react"
import { filtersList } from "../../helpers/catalog"
import clsx from "clsx"
import './Filters.scss'
import { useNavigate, useParams } from "react-router-dom"

const Filters = () => {
  const language = 'ru'
  const params = useParams()
  const filterId = params?.id
  const navigate = useNavigate()

  const [activeIndex, setActiveIndex] = useState(0)
  const [activeId, setActiveId] = useState('set')
  

  useEffect(() => {
    if (filterId) {
      const index = filtersList.findIndex((el, i) => el.id == filterId)

      if (index >= 0) {
        setActiveIndex(index)
        setActiveId(filtersList[index].id)
      }
    }
  }, [])

  const changeActiveIndex = (i) => {
    setActiveIndex(i)
    setActiveId(filtersList[i].id)
    navigate('/catalog/' + filtersList[i].id, {replace: true})
  }

  return (
    <div className="filters">
      {filtersList.map((el, i) => (
        <div className={clsx("filters__item", activeIndex == i ?'active':'')} key={i} onClick={() => changeActiveIndex(i)}>
          {el.name[language]}
        </div>   
        )
      )}
      
    </div>
  )
}

export default Filters