import React from 'react'
import './CatalogPage.scss'
import Header from '../../layout/Header/Header.jsx'
import CatalogSection from './../../sections/CatalogSection/CatalogSection';

const CatalogPage = () => {
	return (
		<div id="luxy" className="wrapper">
			<Header />
			<CatalogSection />
		</div>
	)
}

export default CatalogPage
