import React from "react";

import "./DifferenceSection.scss";

import growingImg1 from "../../assets/images/growing-img-1.jpg";

const DifferenceSection = () => {
  return (
    <section className="section difference-section">
      <div className="container">
        <h2 className="section-title text-center">Семена для микрогрины vs Обычные: В чем разница?</h2>
      </div>
    </section>
  );
};

export default DifferenceSection;
