import React from "react";

import "./StartSection.scss";

import growingImg1 from "../../assets/images/growing-img-1.jpg";

const StartSection = () => {
  return (
    <section className="section start-section">
      <div className="container">
        <h2 className="section-title text-center">С чего стоит начать</h2>
        <p className="section-text text-center">
          Откройте для себя новое хобби с нашими наборами для выращивания
          микрозелени
        </p>

        <div className="start-block">
          <div className="start-block__wrap">
            <div className="start-block__row">
              <div className="start-block__col">
                <div className="start-box">
                  <div className="start-box__wrap">
                    <div className="start-box__img">
                      <img src={growingImg1} alt="" />
                    </div>
                    <div className="start-box__body">
                      <h3 className="start-box__title">Small Box</h3>
                      <div className="start-box__row">
                        <span className="start-box__price start-box__price--old">
                          1000 Грн
                        </span>
                        <span className="start-box__price start-box__price--new">
                          500 Грн
                        </span>
                      </div>
                      <button type="button" className="start-box__btn btn primary">
                        Додати у кошик
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="start-block__col">
                <div className="start-box">
                  <div className="start-box__wrap">
                    <div className="start-box__img">
                      <img src={growingImg1} alt="" />
                    </div>
                    <div className="start-box__body">
                      <h3 className="start-box__title">Medium Box</h3>
                      <div className="start-box__row">
                        <span className="start-box__price start-box__price--old">
                          1000 Грн
                        </span>
                        <span className="start-box__price start-box__price--new">
                          500 Грн
                        </span>
                      </div>
                      <button type="button" className="start-box__btn btn primary">
                        Додати у кошик
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="start-block__col">
                <div className="start-box">
                  <div className="start-box__wrap">
                    <div className="start-box__img">
                      <img src={growingImg1} alt="" />
                    </div>
                    <div className="start-box__body">
                      <h3 className="start-box__title">Big Box</h3>
                      <div className="start-box__row">
                        <span className="start-box__price start-box__price--old">
                          1000 Грн
                        </span>
                        <span className="start-box__price start-box__price--new">
                          500 Грн
                        </span>
                      </div>
                      <button type="button" className="start-box__btn btn primary">
                        Додати у кошик
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StartSection;
