import React from "react";

import "./RestaurantSection.scss";

import growingImg1 from "../../assets/images/growing-img-1.jpg";

const RestaurantSection = () => {
  return (
    <section className="section restaurant-section">
      <div className="container">
        <h2 className="section-title text-center">
          Микрозелень для ресторанов и кафе
        </h2>
        <p className="section-text text-center">
          Для заведений ХОРЕКи мы предоставляем бесплатные образцы под заказ
        </p>
        <div className="restaurant-btn">
          <button type="button" className="btn primary">
            Я хочу бесплатные образцы
          </button>
        </div>
      </div>
    </section>
  );
};

export default RestaurantSection;
