import React from "react";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="container">
        <div className="footer-wrap">
          <div className="footer-wrap__row">
            <div className="footer-wrap__col">
              <div className="footer">
                <div className="footer__logo">
                  <LogoIcon />
                </div>

                <p className="footer__text">
                  Lorem Ipsum is simply dummy text of the and typesetting
                  industry. Lorem Ipsum is dummy text of the printing.
                </p>

                <div className="footer__contact">
                  <span className="footer__icon"></span>
                  <span className="footer__contact-name">
                    Brooklyn, New York, United States
                  </span>
                </div>

                <div className="footer__contact">
                  <span className="footer__icon"></span>
                  <a
                    href="tel:+1234567891"
                    className="footer__contact-name link"
                  >
                    +123 456 78 91
                  </a>
                </div>

                <div className="footer__contact">
                  <span className="footer__icon"></span>
                  <a
                    href="mailto:example@example.com"
                    className="footer__contact-name link"
                  >
                    example@example.com
                  </a>
                </div>

                <div className="footer__socs">
                  <a href="#" className="footer__soc"></a>
                  <a href="#" className="footer__soc"></a>
                </div>
              </div>
            </div>
            <div className="footer-wrap__col">
              <div className="footer">
                <ul className="footer__list">
                  <p className="footer__title">Company</p>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Lorem
                    </a>
                  </li>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Lorem
                    </a>
                  </li>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Lorem
                    </a>
                  </li>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Lorem
                    </a>
                  </li>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Lorem
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="footer-wrap__col">
              <div className="footer">
                <ul className="footer__list">
                  <p className="footer__title">Services.</p>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Lorem
                    </a>
                  </li>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Lorem
                    </a>
                  </li>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Lorem
                    </a>
                  </li>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Lorem
                    </a>
                  </li>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Lorem
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="footer-wrap__col">
              <div className="footer">
                <ul className="footer__list">
                  <p className="footer__title">Customer Care</p>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Lorem
                    </a>
                  </li>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Lorem
                    </a>
                  </li>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Lorem
                    </a>
                  </li>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Lorem
                    </a>
                  </li>
                  <li className="footer__item">
                    <a href="#" className="footer__link">
                      Lorem
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="footer-wrap__col">
              <div className="footer">
                <p className="footer__title">Newsletter</p>
                <p className="footer__text">
                  Subscribe to our weekly Newsletter and receive updates via
                  email.
                </p>
                <form className="footer-form">
                  <div className="footer-form__valid">
                    <div className="footer-form__input-block">
                      <input
                        type="email"
                        className="footer-form__input"
                        placeholder="Email*"
                      />
                      <button
                        type="button"
                        className="footer-form__send"
                      ></button>
                    </div>
                    <p className="footer-form__valid-text">Invalid</p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
