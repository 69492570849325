const filtersList = [
	{
		id: 'set',
		name: {
			ru: 'Наборы для выращивания микрозелени',
			uh: 'Набори для вирощування мікозелені',
		},
	},
	{
		id: 'seads',
		name: {
			ru: 'Семена для микрозелени',
			uh: 'Насіння для мікрозелені',
		},
	},
	{
		id: 'microgreen',
		name: {
			ru: 'Растущая микрозелень',
			uh: 'Мікрозелень що росте',
		},
	},
	{
		id: 'cutmicrogreen',
		name: {
			ru: 'Срез микрозелени',
			uh: 'Зріз мікрозелені',
		},
	},
]

const catalogSeeds = [
	{
		id: 'peasseads',
		title: {
			ru: 'Семена гороха',
			uh: 'Насіння гороху',
		},
		imgUrl: 'seads/PeasS.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1 попция (30гр)',
					uh: '1 попція (30гр)',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
			{
				typeId: '6',
				title: {
					ru: '6 попцый (180гр)',
					uh: '6 попцій (180гр)',
				},
				cost: {
					old: '70.00',
					new: '60.00',
				},
			},
			{
				typeId: '10',
				title: {
					ru: '10 попцый (300гр)',
					uh: '10 попцій (300гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '16',
				title: {
					ru: '16 попцый (480гр)',
					uh: '16 попцій (480гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
		],
	},

	{
		id: 'sunflowerseads',
		title: {
			ru: 'Семена подсолнуха',
			uh: 'Насіння соняха',
		},
		imgUrl: 'seads/sunflowerS.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1 попция (15гр)',
					uh: '1 попція (15гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '6',
				title: {
					ru: '6 попцый (90гр)',
					uh: '6 попцій (90гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '10',
				title: {
					ru: '10 попцый (150гр)',
					uh: '10 попцій (150гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '16',
				title: {
					ru: '16 попцый (240гр)',
					uh: '16 попцій (240гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
		],
	},

	{
		id: 'wheatseads',
		title: {
			ru: 'Семена пшеницы',
			uh: 'Насіння пшениці',
		},
		imgUrl: 'seads/WheatS.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1 попция (30гр)',
					uh: '1 попція (30гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '6',
				title: {
					ru: '6 попцый (180гр)',
					uh: '6 попцій (180гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '10',
				title: {
					ru: '10 попцый (300гр)',
					uh: '10 попцій (300гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '16',
				title: {
					ru: '16 попцый (480гр)',
					uh: '16 попцій (480гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
		],
	},

	{
		id: 'sungoseads',
		title: {
			ru: 'Семена редиса Sungo',
			uh: 'Насіння редиски Sungo',
		},
		imgUrl: 'seads/SungoS.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1 попция (6гр)',
					uh: '1 попція (6гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '6',
				title: {
					ru: '6 попцый (36гр)',
					uh: '6 попцій (36гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '10',
				title: {
					ru: '10 попцый (60гр)',
					uh: '10 попцій (60гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '16',
				title: {
					ru: '16 попцый (96гр)',
					uh: '16 попцій (96гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
		],
	},

	{
		id: 'redisgreenseads',
		title: {
			ru: 'Семена редиса зеленого',
			uh: 'Насіння редиски зеленої',
		},
		imgUrl: 'seads/RedisGreenS.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1 попция (6гр)',
					uh: '1 попція (6гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '6',
				title: {
					ru: '6 попцый (36гр)',
					uh: '6 попцій (36гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '10',
				title: {
					ru: '10 попцый (60гр)',
					uh: '10 попцій (60гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '16',
				title: {
					ru: '16 попцый (96гр)',
					uh: '16 попцій (96гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
		],
	},

	{
		id: 'redischinaroseseads',
		title: {
			ru: 'Семена редиса China Rose',
			uh: 'Насіння редиски China Rose',
		},
		imgUrl: 'seads/ChinaRoseS.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1 попция (6гр)',
					uh: '1 попція (6гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '6',
				title: {
					ru: '6 попцый (36гр)',
					uh: '6 попцій (36гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '10',
				title: {
					ru: '10 попцый (60гр)',
					uh: '10 попцій (60гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '16',
				title: {
					ru: '16 попцый (96гр)',
					uh: '16 попцій (96гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
		],
	},

	{
		id: 'redisredcoralseads',
		title: {
			ru: 'Семена редиса Red Coral',
			uh: 'Насіння редиски Red Coral',
		},
		imgUrl: 'seads/RedCoralS.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1 попция (6гр)',
					uh: '1 попція (6гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '6',
				title: {
					ru: '6 попцый (36гр)',
					uh: '6 попцій (36гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '10',
				title: {
					ru: '10 попцый (60гр)',
					uh: '10 попцій (60гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '16',
				title: {
					ru: '16 попцый (96гр)',
					uh: '16 попцій (96гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
		],
	},

	{
		id: 'daikonseads',
		title: {
			ru: 'Семена редьки Дайкон',
			uh: 'Насіння редьки Дайкон',
		},
		imgUrl: 'seads/DaikonS.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1 попция (6гр)',
					uh: '1 попція (6гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '6',
				title: {
					ru: '6 попцый (36гр)',
					uh: '6 попцій (36гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '10',
				title: {
					ru: '10 попцый (60гр)',
					uh: '10 попцій (60гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '16',
				title: {
					ru: '16 попцый (96гр)',
					uh: '16 попцій (96гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
		],
	},

	{
		id: 'cilantroseads',
		title: {
			ru: 'Семена кинзы',
			uh: 'Насіння кінзи',
		},
		imgUrl: 'seads/CilantroS.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1 попция (10гр)',
					uh: '1 попція (10гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '6',
				title: {
					ru: '6 попцый (60гр)',
					uh: '6 попцій (60гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '10',
				title: {
					ru: '10 попцый (100гр)',
					uh: '10 попцій (100гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '16',
				title: {
					ru: '16 попцый (160гр)',
					uh: '16 попцій (160гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
		],
	},

	{
		id: 'watercresssaladseads',
		title: {
			ru: 'Семена кресс салата',
			uh: 'Насіння крес салату',
		},
		imgUrl: 'seads/WatercresssaladS.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1 попция (4гр)',
					uh: '1 попція (4гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '6',
				title: {
					ru: '6 попцый (24гр)',
					uh: '6 попцій (24гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '10',
				title: {
					ru: '10 попцый (40гр)',
					uh: '10 попцій (40гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '16',
				title: {
					ru: '16 попцый (64гр)',
					uh: '16 попцій (64гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
		],
	},

	{
		id: 'broccoliseads',
		title: {
			ru: 'Семена брокколи',
			uh: 'Насіння броколі',
		},
		imgUrl: 'seads/BroccoliS.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1 попция (4гр)',
					uh: '1 попція (4гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '6',
				title: {
					ru: '1 попция (24гр)',
					uh: '1 попція (24гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '10',
				title: {
					ru: '10 попцый (40гр)',
					uh: '10 попцій (40гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '16',
				title: {
					ru: '16 попцый (64гр)',
					uh: '16 попцій (64гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
		],
	},

	{
		id: 'lucerneseads',
		title: {
			ru: 'Семена люцерны',
			uh: 'Насіння люцерни',
		},
		imgUrl: 'seads/LucerneS.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1 попция (7гр)',
					uh: '1 попція (7гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '6',
				title: {
					ru: '1 попция (42гр)',
					uh: '1 попція (42гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '10',
				title: {
					ru: '10 попцый (70гр)',
					uh: '10 попцій (70гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '16',
				title: {
					ru: '16 попцый (112гр)',
					uh: '16 попцій (112гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
		],
	},

	{
		id: 'сornseads',
		title: {
			ru: 'Семена кукурузы',
			uh: 'Насіння кукурудзи',
		},
		imgUrl: 'seads/CornS.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1 попция (30гр)',
					uh: '1 попція (30гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '6',
				title: {
					ru: '1 попция (180гр)',
					uh: '1 попція (180гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '10',
				title: {
					ru: '10 попцый (300гр)',
					uh: '10 попцій (300гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '16',
				title: {
					ru: '16 попцый (480гр)',
					uh: '16 попцій (480гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
		],
	},

	{
		id: 'onionseads',
		title: {
			ru: 'Семена лука',
			uh: 'Насіння цибулі',
		},
		imgUrl: 'seads/OnionS.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1 попция (10гр)',
					uh: '1 попція (10гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '6',
				title: {
					ru: '1 попция (60гр)',
					uh: '1 попція (60гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '10',
				title: {
					ru: '10 попцый (100гр)',
					uh: '10 попцій (100гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '16',
				title: {
					ru: '16 попцый (160гр)',
					uh: '16 попцій (160гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
		],
	},

	{
		id: 'kohlrabiseads',
		title: {
			ru: 'Семена кольраби',
			uh: 'Насіння кольрабі',
		},
		imgUrl: 'seads/KohlrabiS.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1 попция (4гр)',
					uh: '1 попція (4гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '6',
				title: {
					ru: '1 попция (24гр)',
					uh: '1 попція (24гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '10',
				title: {
					ru: '10 попцый (40гр)',
					uh: '10 попцій (40гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '16',
				title: {
					ru: '16 попцый (64гр)',
					uh: '16 попцій (64гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
		],
	},

	{
		id: 'beetrootseads',
		title: {
			ru: 'Семена свеклы',
			uh: 'Насіння буряка',
		},
		imgUrl: 'seads/BeetrootS.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1 попция (5гр)',
					uh: '1 попція (5гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '6',
				title: {
					ru: '1 попция (30гр)',
					uh: '1 попція (30гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '10',
				title: {
					ru: '10 попцый (50гр)',
					uh: '10 попцій (50гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
			{
				typeId: '16',
				title: {
					ru: '16 попцый (80гр)',
					uh: '16 попцій (80гр)',
				},
				cost: {
					old: '30.00',
					new: '20.00',
				},
			},
		],
	},
]

const catalogMicrogreen = [
	{
		id: 'peasMicro',
		title: {
			ru: 'Горох',
			uh: 'Горох',
		},
		imgUrl: 'microgreen/peas.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'sunflowerMicro',
		title: {
			ru: 'Подсолнух',
			uh: 'Сонях',
		},
		imgUrl: '',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'wheatMicro',
		title: {
			ru: 'Пшеница',
			uh: 'Пшениця',
		},
		imgUrl: '',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'sungoMicro',
		title: {
			ru: 'Редис Sungo',
			uh: 'Редиска Sungo',
		},
		imgUrl: '',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'redisgreenMicro',
		title: {
			ru: 'Редис зеленый',
			uh: 'Редиска зелена',
		},
		imgUrl: 'microgreen/Redish green.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'redischinaroseMicro',
		title: {
			ru: 'Редис China Rose',
			uh: 'Редиска China Rose',
		},
		imgUrl: '',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'redisredcoralMicro',
		title: {
			ru: 'Редис Red Coral',
			uh: 'Редискf Red Coral',
		},
		imgUrl: 'microgreen/Red Coral.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'daiconMicro',
		title: {
			ru: 'Редька Дайкон',
			uh: 'Редька Дайкон',
		},
		imgUrl: 'microgreen/Daicon.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'cilantroMicro',
		title: {
			ru: 'Кинза',
			uh: 'Кінза',
		},
		imgUrl: '',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'watercresssaladMicro',
		title: {
			ru: 'Кресс салат',
			uh: 'Крес салат',
		},
		imgUrl: '',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'broccoliMicro',
		title: {
			ru: 'Брокколи',
			uh: 'Броколі',
		},
		imgUrl: 'microgreen/Broccoli.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'onionMicro',
		title: {
			ru: 'Лук',
			uh: 'Цибуля',
		},
		imgUrl: '',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'kohlrabiMicro',
		title: {
			ru: 'Кольраби',
			uh: 'Кольрабі',
		},
		imgUrl: 'microgreen/Kohlrabi.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'beetrootMicro',
		title: {
			ru: 'Свекла',
			uh: 'Буряк',
		},
		imgUrl: 'microgreen/Beetroot.jpg',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},
]

const catalogCutMicrogreen = [
	{
		id: 'peas',
		title: {
			ru: 'Горох',
			uh: 'Горох',
		},
		imgUrl: '',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'sunflower',
		title: {
			ru: 'Подсолнух',
			uh: 'Сонях',
		},
		imgUrl: '',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'wheat',
		title: {
			ru: 'Пшеница',
			uh: 'Пшениця',
		},
		imgUrl: '',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'sungo',
		title: {
			ru: 'Редис Sungo',
			uh: 'Редиска Sungo',
		},
		imgUrl: '',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'redisgreen',
		title: {
			ru: 'Редиса зеленый',
			uh: 'Редиска зелена',
		},
		imgUrl: '',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'redischinarose',
		title: {
			ru: 'Редис China Rose',
			uh: 'Редиска China Rose',
		},
		imgUrl: '',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'redisredcoral',
		title: {
			ru: 'Редиса Red Coral',
			uh: 'Редиски Red Coral',
		},
		imgUrl: '',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'daicon',
		title: {
			ru: 'Редька Дайкон',
			uh: 'Редька Дайкон',
		},
		imgUrl: '',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'cilantro',
		title: {
			ru: 'Кинза',
			uh: 'Кінза',
		},
		imgUrl: '',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'watercresssalad',
		title: {
			ru: 'Кресс салат',
			uh: 'Крес салат',
		},
		imgUrl: '',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'broccoli',
		title: {
			ru: 'Брокколи',
			uh: 'Броколі',
		},
		imgUrl: '',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'onion',
		title: {
			ru: 'Лук',
			uh: 'Цибуля',
		},
		imgUrl: '',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'kohlrabi',
		title: {
			ru: 'Кольраби',
			uh: 'Кольрабі',
		},
		imgUrl: '',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},

	{
		id: 'beetroot',
		title: {
			ru: 'Свекла',
			uh: 'Буряк',
		},
		imgUrl: '',
		isInStock: true,
		priceList: [
			{
				typeId: '1',
				title: {
					ru: '1шт',
					uh: '1шт',
				},
				cost: {
					old: '15.00',
					new: '13.00',
				},
			},
		],
	},
]

export { catalogSeeds, filtersList, catalogMicrogreen, catalogCutMicrogreen }
