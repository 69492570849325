import React from "react";

import "./LoveSection.scss";

import growingImg1 from "../../assets/images/growing-img-1.jpg";

const LoveSection = () => {
  return (
    <section className="section love-section">
      <div className="container">
        <h2 className="section-title text-center">Мы выращиваем для Вас с любовью</h2>
      </div>
    </section>
  );
};

export default LoveSection;
