import React, {useEffect, useState} from "react";

import './CatalogSection.scss'

import Filters from "../../components/Filters/Filters";

import clsx from "clsx";
import { catalogSeeds, catalogCutMicrogreen, catalogMicrogreen } from "../../helpers/catalog";
import CatalogCard from "../../components/CatalogCard/CatalogCard";
import { useParams } from "react-router-dom";

const catalogSet = []

const CatalogSection = () => {
  const params = useParams()
  
  const [isTable, setIsTable] = useState(true);
  const [activeSection, srtActiveSection] = useState('set')
  const [catalogList, setCatalogList] = useState([])

  useEffect(() => {
    if (params.id != activeSection) {
      srtActiveSection(params.id)
    }
  }, [params.id])

  useEffect(() => {
    if (activeSection == 'set') {
      setCatalogList(catalogSet)
    } else if (activeSection == 'seads') {
      setCatalogList(catalogSeeds)
    } else if (activeSection == 'microgreen') {
      setCatalogList(catalogMicrogreen)
    } else if (activeSection == 'cutmicrogreen') {
      setCatalogList(catalogCutMicrogreen)
    } else {
      setCatalogList(catalogSet)
    }
  }, [activeSection])
  

  return (
    <section className="section catalog-section">
      <div className="container">
        <div className="catalog">
          <div className="catalog__wrap">
            <div className="catalog__filters mr-3">
              <Filters/>
            </div>
            <div className="catalog__content">
              <div className="catalog-view mb-3">
                <div className={clsx("catalog-view__item catalog-view__item--tible", isTable?'active': '')} onClick={() => setIsTable(true)}>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>

                <div className={clsx("catalog-view__item catalog-view__item--col",
                  isTable ? '' : 'active')} onClick={() => setIsTable(false)}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>

              <div className={clsx("catalog__list", !isTable ? 'catalog__list--col' : '')}>
                {catalogList?.map((el, i) => (
                  <div className="catalog__card" key={i}>
                    <CatalogCard data={el} isTable={isTable} />
                  </div>
                ))}
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

export default CatalogSection