import React from "react";

import './AboutSection.scss'

import aboutImg from '../../assets/images/about-img.jpg'

const AboutSection = () => {
  return (
    <section className="section about-section">
      <div className="container">
        <div className="about-wrap">
          <div className="about-wrap__row">
            <div className="about-wrap__col">
              <div className="about">
                <h2 className="section-title">Микрогрин: Сила в Маленьких Проростках</h2>
                <p className="base-text">
                  Микрогрин - это миниатюрные, но невероятно могущественные растения, которые за считанные недели (6-21 день, в зависимости от сорта и условий) превращаются в зеленую сокровищницу вкуса и питательных веществ. Этот феномен прямо сейчас завоевывает мир, становясь неотъемлемой частью меню ресторанов по всей планете и популярным выбором для тех, кто ценит здоровое питание. Микрогрин - это маленький, но мощный секрет, который добавляет интриги и вкуса в ваши блюда, и приносит удовольствие всему вашему организму.
                </p>
              </div>
            </div>

            <div className="about-wrap__col">
              <div className="about">
                <div className="about__img">
                  <img src={aboutImg} alt="microgreen"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutSection